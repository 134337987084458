import React from 'react';

const Intro = () => {
    return (
        <div className="intro">
            <p>Als Bingel door onderhoudswerken of onvoorziene omstandigheden niet bereikbaar is, informeren we je hierover op deze pagina.</p> Bingel wordt continu verbeterd. Daartoe voeren we regelmatig updates door. Tijdens die updates blijft Bingel gewoon beschikbaar. Af en toe is Bingel tijdelijk niet beschikbaar voor grotere, aangekondigde updates of aanpassingen. Om ervoor te zorgen dat gebruikers daar zo weinig mogelijk last van hebben, plannen we die onderhoudswerken in bij voorkeur ’s nachts, in het weekend of tijdens een vakantie.
        </div>
    )
}

export default Intro;