import React, {Component} from 'react';
import axios from 'axios';

function formatDate(value, locale = 'nl-BE') {
    return new Date(value).toLocaleDateString(locale);
}

class Content extends Component {
  // State of your application
  state = {
    messages: [],
    error: null,
  };

  // Fetch your messages immediately after the component is mounted
  componentDidMount = async () => {
    try {
      const { data } = await axios.get('https://strapi.vanin.be/api/bingel-messages');
      this.setState({ messages: data.data });
    } catch (error) {
      this.setState({ error });
    }
  };

  render() {
    const { error, messages } = this.state;

    // Print errors if any
    if (error) {
      return <div>An error occured: {error.message}</div>;
    }

    return (
      <div className="list-type">
        <ul>
          {messages
            .sort((a, b) => b.id - a.id)
            .map((message) => {
              const { id, attributes } = message
              return (
                <li key={id}>
                  <h4><strong>{formatDate(attributes.Date)} - {attributes.Time.slice(0,-3)}</strong></h4>
                  {attributes.Message}
                </li>
              )
          })}
        </ul>
      </div>
    );
  }
}

export default Content;